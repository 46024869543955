@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
	font-family: Roboto;
}

.content {
	position: relative;
}

.container {
	position: absolute;
	right: 50%;
	transform: translateX(50%);
	top: 30%;
	text-shadow: 2px 2px rgb(25, 118, 210);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}

.signin-link {
	color: white;
	text-decoration: none;
	transition: all 200ms ease-in 0s;
}

.signin-link:hover {
	transform: scale(1.05);
}

.link:hover {
	text-decoration: underline;
	cursor: pointer;
}